import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Redirect, Route, Switch} from 'react-router-dom'
import TrackingNumbersContainer from '../../tracking-numbers/components/TrackingNumbersContainer'
import TrackingMappingContainer from '../../tracking-mapping/components/TrackingMappingContainer'
import TrackingSourceContainer from '../../tracking-source/components/TrackingSourceContainer'
import StatisticsContainer from '../../statistics/components/StatisticsContainer'
import ProfileContainer from '../../profile/components/ProfileContainer'
import ManageUsersContainer from '../../manage-users/components/ManageUsersContainer'
import ManageAPIKeysContainer from '../../manage-apikeys/components/ManageAPIKeysContainer'
import ManageWebhooksContainer from '../../manage-webhooks/components/ManageWebhooksContainer'
import SecurityContainer from '../../security/components/SecurityContainer'
import ManageJobsContainer from '../../manage-jobs/components/ManageJobsContainer'
import ManageProxyContainer from "../../manage-proxy/components/ManageProxyContainer"
import TrackingCodeCrawlContainer from '../../tracking-code-crawl/components/TrackingCodeCrawlContainer'
import WebhookSettingContainer from "../../webhook-setting/components/WebhookSettingContainer"
import WebhookNotificationContainer from "../../webhook-notification/components/WebhookNotificationContainer"

class MainApp extends Component {
    render() {

        const {basePath} = this.props

        return (
            <Switch>
                <Route
                    exact
                    path={`${basePath}/statistics`}
                    render={() => <StatisticsContainer basePath={basePath}/>}
                />

                <Route
                    exact
                    path={`${basePath}/tracking-numbers`}
                    render={() => <TrackingNumbersContainer basePath={basePath}/>}
                />

                <Route
                    exact
                    path={`${basePath}/tracking-mapping`}
                    render={() => <TrackingMappingContainer basePath={basePath}/>}
                />

                <Route
                    exact
                    path={`${basePath}/tracking-source`}
                    render={() => <TrackingSourceContainer basePath={basePath}/>}
                />

                <Route
                    exact
                    path={`${basePath}/tracking-code-crawl`}
                    render={() => <TrackingCodeCrawlContainer basePath={basePath} />}
                />

                <Route
                    path={`${basePath}/profile`} render={() => <ProfileContainer basePath={basePath}/>}
                />

                <Route
                    path={`${basePath}/security`} render={() => <SecurityContainer basePath={basePath}/>}
                />

                <Route
                    path={`${basePath}/manage-apikeys`}
                    render={() => <ManageAPIKeysContainer basePath={basePath}/>}
                />

                <Route
                    path={`${basePath}/manage-webhooks`}
                    render={() => <ManageWebhooksContainer basePath={basePath}/>}
                />

                <Route
                    path={`${basePath}/webhook-setting`}
                    render={() => <WebhookSettingContainer basePath={basePath}/>}
                />
                <Route
                    path={`${basePath}/webhook-notification`}
                    render={() => <WebhookNotificationContainer basePath={basePath}/>}
                />

                <Route
                    path={`${basePath}/manage-users`} render={() => <ManageUsersContainer basePath={basePath}/>}
                />

                <Route
                    path={`${basePath}/manage-jobs`} render={() => <ManageJobsContainer basePath={basePath}/>}
                />

                <Route
                    path={`${basePath}/manage-proxy`} render={() => <ManageProxyContainer basePath={basePath}/>}
                />

                <Route path={'*'}>
                    <Redirect to={`${basePath}/statistics`}/>
                </Route>
            </Switch>
        )
    }
}

MainApp.propTypes = {
    basePath: PropTypes.string.isRequired,
}

export default MainApp
